import React, { ReactNode } from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./pageContainer.module.scss"

export type PageContainerProps = {
  children: ReactNode
}

export function PageContainer({ children }: PageContainerProps) {
  return (
    <section className={styles.root}>
      <Container>{children}</Container>
    </section>
  )
}

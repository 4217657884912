import React from "react"

import Button from "@material-ui/core/Button"
import { PageContainer } from "../../components/inmyroom/PageContainer"
import { Layout } from "../../components/Layout"

const seoData = {
  title: "Спасибо!",
}

export default function ErrorPage() {
  return (
    <Layout seoData={seoData} hasCustomFooter>
      <PageContainer>
        <h1 className="head">Спасибо!</h1>
        <p className="description">
          Если у вас нет карты, нажмите на кнопку и перейдите на страницу оформления. После
          заполнения формы, на телефон придет смс с промокодом на дополнительные 3 месяца рассрочки!
        </p>
        <Button href="https://halvacard.ru/order/?utm_medium=Display&utm_source=youtube.com&utm_campaign=inmyroom_July_1_halva_link1_promokod">
          Оформить карту
        </Button>
      </PageContainer>
    </Layout>
  )
}
